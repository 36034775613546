import styled, { css } from "styled-components";

import media from "constants/media";

import Button from "components/ui/button/Button";

export const Container = styled.header`
    position: fixed;
    top: 0;
    left: 0;
    z-index: 30;

    display: flex;
    width: 100vw;
    height: 60px;

    background: linear-gradient(-45deg, transparent 20px, var(--color-white) 0);

    :before {
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;

        width: calc(100% - 30px);
        height: 100%;

        box-shadow: -20px 3px 44px 0 rgba(0, 0, 0, 0.13);

        content: "";
        pointer-events: none;
    }

    ${media.mdUp} {
        justify-content: right;
        width: calc(100vw);
        height: 100px;
        margin-right: 0;

        background-color: var(--color-white);

        :before {
            display: none;
        }
    }

    ${media.lgUp} {
        width: calc(50vw + var(--container-width) / 2 + 185px);
        margin-left: -10px;
        padding-right: 185px;

        clip-path: polygon(0 0, 0 100%, calc(100% - 80px) 100%, 100% 0);
    }
`;

export const LogoLink = styled.a`
    position: relative;

    display: flex;
    flex: 0 0 auto;
    align-items: center;
    justify-content: center;
    width: 91px;
    height: 100%;

    cursor: pointer;

    ${({ showMenu }) =>
        !showMenu &&
        css`
            margin: 0 auto;
        `}

    ${media.mdUp} {
        width: 145px;
        height: 46px;

        ${({ showMenu }) =>
            !showMenu &&
            css`
                position: absolute;
                left: 50%;
                height: 100%;

                transform: translateX(calc(-50%));
            `}
    }

    ${media.lgUp} {
        width: 145px;
        height: 46px;

        ${({ showMenu }) =>
            showMenu &&
            css`
                position: relative;
                left: auto;

                width: 100px;
                height: 31px;

                transform: none;
            `}
    }

    ${media.xlUp} {
        width: 145px;
        height: 46px;
    }
`;

export const Wrapper = styled.div`
    position: relative;
    z-index: 30;

    display: flex;
    gap: 0 15px;
    align-items: stretch;
    width: 100%;
    padding: 0;

    font-size: 13px;
    line-height: 18px;

    ${media.mdUp} {
        align-items: center;
        padding: 0 15px;
    }

    ${media.lgUp} {
        max-width: calc(var(--container-width) - 20px);
        padding: 0;
    }

    ${media.xlUp} {
        padding: 0;
    }
`;

export const OrderButton = styled(Button)`
    position: relative;
    z-index: 0;

    flex: 0 0 auto;
    margin-left: auto;

    ${media.mdDown} {
        justify-self: stretch;
        height: 100%;
        padding: 0 25px;

        color: var(--color-white);
        font-size: 13px;

        background: linear-gradient(
            -45deg,
            transparent 20px,
            var(--color-primary) 0
        );

        border-radius: 0;
        box-shadow: none;
    }
`;
