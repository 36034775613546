import PropTypes from "prop-types";

function IconCheckmark({ fill, size, ...rest }) {
    return (
        <svg
            viewBox="0 0 64 64"
            width={size}
            height={size}
            clipRule="evenodd"
            fillRule="evenodd"
            fill={fill}
            {...rest}
        >
            <path d="m53 14-26.9 26.9-14.9-14.9-4.2 4.3 19.1 19.1 4.2-4.3 26.9-26.9z" />
        </svg>
    );
}

IconCheckmark.propTypes = {
    fill: PropTypes.string,
    size: PropTypes.number,
    style: PropTypes.shape({ verticalAlign: PropTypes.string })
};

IconCheckmark.defaultProps = {
    fill: "var(--color-black)",
    size: 26,
    style: { verticalAlign: "middle" }
};

export default IconCheckmark;
