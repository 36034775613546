import PropTypes from "prop-types";
import { useState } from "react";
import Link from "next/link";
import Image from "next/image";

import { useMediaQueryContext } from "helpers/hooks/useMediaQueryContext";

import Hamburger from "./hamburger/Hamburger";
import Menu from "./menu/Menu";
import Navigation from "./navigation/Navigation";

import { Container, Wrapper, LogoLink, OrderButton } from "./Header.styled";

function Header({ showMenu }) {
    const [open, setOpen] = useState(false);
    const { desktopView } = useMediaQueryContext();

    return (
        <Container>
            <Wrapper>
                {!desktopView && showMenu && (
                    <>
                        <Hamburger setOpen={setOpen} open={open} />
                        <Menu open={open} setOpen={setOpen} />
                    </>
                )}
                <Link href="/" passHref prefetch={false}>
                    <LogoLink showMenu={showMenu && desktopView}>
                        <Image
                            src="/images/logos/logo-kcc-color.svg"
                            alt="Nationale Kunst & Cultuur Cadeaukaart logo"
                            layout="fill"
                            priority
                        />
                    </LogoLink>
                </Link>
                {showMenu && (
                    <>
                        {desktopView && <Navigation />}
                        <Link
                            href="/cultuur-cadeau-bestellen/"
                            passHref
                            prefetch={false}
                        >
                            <OrderButton element="a" variant="primary">
                                nu bestellen
                            </OrderButton>
                        </Link>
                    </>
                )}
            </Wrapper>
        </Container>
    );
}

Header.propTypes = {
    showMenu: PropTypes.bool
};

Header.defaultProps = {
    showMenu: true
};

export default Header;
