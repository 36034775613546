import PropTypes from "prop-types";
import Link from "next/link";
import { useTrail, a, config, useTransition } from "@react-spring/web";

import navigationItems from "constants/navigation";

import { useMediaQueryContext } from "helpers/hooks/useMediaQueryContext";

import { Navigation, Overlay, List, Anchor } from "./Menu.styled";

function Menu({ open, setOpen }) {
    const { containerWidth } = useMediaQueryContext();
    const trail = useTrail(navigationItems.length, {
        config: config.stiff,
        x: open ? 0 : -5,
        opacity: open ? 1 : 0,
        from: { opacity: 0, x: -5 }
    });
    const transitions = useTransition(open, {
        from: {
            width: "0vw",
            height: "0vh",
            opacity: 0
        },
        enter: {
            width: "150vw",
            height: "150vh",
            opacity: 0.5
        },
        leave: {
            width: "0vw",
            height: "0vh",
            opacity: 0
        },
        reverse: open
    });

    return (
        <>
            <Navigation
                open={open}
                containerWidth={containerWidth}
                role="navigation"
                aria-label="Main"
            >
                <List>
                    {trail.map(({ ...style }, index) => (
                        <a.li
                            key={JSON.stringify(navigationItems[index].label)}
                            style={style}
                        >
                            <Link
                                href={navigationItems[index].url}
                                passHref
                                prefetch={false}
                            >
                                <Anchor
                                    isLast={
                                        index + 1 === navigationItems.length
                                    }
                                >
                                    {navigationItems[index].label}
                                </Anchor>
                            </Link>
                        </a.li>
                    ))}
                </List>
            </Navigation>
            {transitions(
                (styles, item) =>
                    item && (
                        <Overlay
                            style={styles}
                            open={open}
                            onClick={() => {
                                setOpen(!open);
                            }}
                        />
                    )
            )}
        </>
    );
}

Menu.propTypes = {
    open: PropTypes.bool,
    setOpen: PropTypes.func
};

Menu.defaultProps = {
    open: false,
    setOpen: () => {}
};

export default Menu;
