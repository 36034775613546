import IconCross from "components/ui/icons/IconCross";
import IconCheckmark from "components/ui/icons/IconCheckmark";

export function getValidationIcon(type, errors) {
    if (!type && !errors) {
        return undefined;
    }
    if (errors) {
        return <IconCross size={25} fill="var(--color-red-50)" />;
    }

    return <IconCheckmark size={25} fill="var(--color-green-50)" />;
}

export function getBackgroundColor(isValid, hasError) {
    if (hasError) {
        return "var(--color-red-60)";
    }
    if (isValid) {
        return "var(--color-green-60)";
    }

    return "var(--color-violet-90)";
}

export function getBorder(hasError, isValid) {
    if (hasError) {
        return "1px solid var(--color-red-50)";
    }
    if (isValid) {
        return "1px solid var(--color-green-50)";
    }

    return "1px solid var(--color-violet-85)";
}
