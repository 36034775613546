import PropTypes from "prop-types";
import { forwardRef } from "react";

import {
    InputStyled,
    LeftIcon,
    LabelText,
    Field,
    Label,
    RightIcon,
    OptionalLabel,
    Error,
    FieldWrapper
} from "./Input.styled";

const Input = forwardRef(
    (
        {
            leftIcon,
            rightIcon,
            errorMessage,
            errorMessageWidth,
            label,
            hideLabelText,
            isValid,
            optional,
            ...props
        },
        ref
    ) => {
        return (
            <InputStyled>
                <Label>
                    {label && (
                        <LabelText hidden={hideLabelText}>
                            {label}
                            {optional && (
                                <OptionalLabel>Optioneel</OptionalLabel>
                            )}
                        </LabelText>
                    )}
                    <FieldWrapper
                        hasLeftIcon={!!leftIcon}
                        hasRightIcon={!!rightIcon}
                        isValid={!!isValid}
                        hasError={!!errorMessage}
                    >
                        {leftIcon && <LeftIcon>{leftIcon}</LeftIcon>}
                        <Field
                            hasLeftIcon={!!leftIcon}
                            hasRightIcon={!!rightIcon}
                            hasError={!!errorMessage}
                            ref={ref}
                            {...props}
                        />
                        {rightIcon && <RightIcon>{rightIcon}</RightIcon>}
                    </FieldWrapper>
                </Label>

                {errorMessage && (
                    <Error errorMessageWidth={errorMessageWidth}>
                        {errorMessage}
                    </Error>
                )}
            </InputStyled>
        );
    }
);

Input.propTypes = {
    leftIcon: PropTypes.node,
    rightIcon: PropTypes.node,
    errorMessage: PropTypes.string,
    errorMessageWidth: PropTypes.string,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    hideLabelText: PropTypes.bool,
    isValid: PropTypes.bool,
    optional: PropTypes.bool,
    className: PropTypes.string
};

Input.defaultProps = {
    leftIcon: undefined,
    rightIcon: undefined,
    errorMessage: undefined,
    errorMessageWidth: undefined,
    label: undefined,
    hideLabelText: false,
    isValid: false,
    optional: false,
    className: ""
};

export default Input;
