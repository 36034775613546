import styled, { css } from "styled-components";

import media from "constants/media";
import { getBackgroundColor, getBorder } from "helpers/input";

export const InputStyled = styled.div`
    position: relative;

    width: 100%;
    margin-bottom: 28px;

    input[type="number"] {
        /* stylelint-disable-next-line property-no-vendor-prefix */
        -moz-appearance: textfield;
    }

    input[type="number"]::-webkit-inner-spin-button,
    input[type="number"]::-webkit-outer-spin-button {
        margin: 0;
        /* stylelint-disable-next-line property-no-vendor-prefix */
        -webkit-appearance: none;
    }
`;

export const LabelText = styled.span`
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;

    font-size: var(--font-size-body);
    line-height: var(--line-height-body);

    ${({ hidden }) =>
        hidden &&
        css`
            position: absolute;
            left: 100%;

            width: 0;
            height: 0;

            overflow: hidden;

            opacity: 0;
        `}
`;

export const OptionalLabel = styled.span`
    font-size: var(--font-size-body);
    line-height: var(--line-height-body);
    text-align: right;

    opacity: 0.5;
`;

function getFieldPadding(hasLeftIcon, hasRightIcon) {
    const leftPadding = hasLeftIcon ? "14px" : "20px";
    const rightPadding = hasRightIcon ? "14px" : "20px";

    return `0 ${rightPadding} 0 ${leftPadding}`;
}

export const FieldWrapper = styled.div`
    display: flex;
    align-items: center;
    width: auto;
    height: 50px;
    overflow: hidden;

    font-size: var(--font-size-body);
    line-height: var(--line-height-body);

    background-color: ${({ isValid, hasError }) =>
        getBackgroundColor(isValid, hasError)};

    border: ${({ hasError, isValid }) => getBorder(hasError, isValid)};
    border-radius: var(--border-radius-default);

    outline: 0;
    inset: 0;

    transition: all var(--animation-duration-default) ease;
`;

export const Label = styled.label`
    position: relative;

    display: block;

    :focus,
    :hover,
    :focus-within {
        ${FieldWrapper} {
            background-color: var(--color-white);
            border-radius: var(--border-radius-default);
            box-shadow: var(--box-shadow-input);
        }
    }
`;

export const Field = styled.input`
    display: flex;
    align-items: center;
    width: 100%;
    height: 45px;
    padding: ${({ hasLeftIcon, hasRightIcon }) =>
        getFieldPadding(hasLeftIcon, hasRightIcon)};

    color: var(--color-tertiary);
    font-size: var(--font-size-body);
    line-height: var(--line-height-body);

    background-color: transparent;
    border: 0;
    outline: none;

    transition: all var(--animation-duration-default) ease;

    ${({ hasLeftIcon }) =>
        hasLeftIcon &&
        css`
            padding-left: 0;
        `};

    ${({ hasRightIcon }) =>
        hasRightIcon &&
        css`
            padding-right: 0;
        `};

    ::placeholder {
        color: var(--color-tertiary);
        font-size: 14px;
        line-height: var(--line-height-body);

        opacity: 0.5;
    }

    ${media.mdUp} {
        height: 50px;
    }
`;

const Icon = styled.div`
    z-index: 7;

    display: flex;
    align-items: center;
    justify-content: center;
    width: 25px;
    height: 25px;

    cursor: pointer;

    img {
        width: 17px;
        max-height: 17px;
    }
`;

export const LeftIcon = styled(Icon)`
    left: 16px;

    width: 50px;
    height: 50px;
`;

export const RightIcon = styled(Icon)`
    margin-right: 14px;
`;

export const Error = styled.p`
    position: absolute;
    bottom: -15px;
    left: ${({ errorMessageWidth }) => (errorMessageWidth ? "50%" : "0")};

    width: ${({ errorMessageWidth }) => errorMessageWidth || "100%"};

    color: var(--color-red-50);
    font-size: 12px;
    line-height: 20px;
    white-space: nowrap;
    text-align: left;

    transform: translate(
        ${({ errorMessageWidth }) => (errorMessageWidth ? "-50% " : "0")},
        100%
    );
`;
