export default [
    {
        url: "/waar-te-besteden/",
        label: "Waar te besteden"
    },
    {
        url: "/saldocheck/",
        label: "Saldocheck"
    },
    {
        url: "/cultuur-cadeau-bestellen/",
        label: "Bestellen"
    },
    {
        url: "/verkooppunten/",
        label: "Verkooppunten"
    },
    {
        url: "/zakelijk/",
        label: "Zakelijk"
    },
    {
        url: "/helpdesk/",
        label: "Helpdesk"
    },
    {
        url: "/acceptant-worden/",
        label: "Acceptant"
    },
    {
        url: "/verdubbelaar/",
        label: "Verdubbelaar"
    }
];
