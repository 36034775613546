import styled, { css } from "styled-components";

const roundedButtonStyling = css`
    margin: 0;
    padding: 10px 25px;
    overflow: hidden;

    font-weight: var(--font-weight-bold);
    font-size: var(--font-size-body);
    line-height: var(--line-height-body);

    border-radius: 30em;

    transition: background var(--animation-duration-default) ease;
`;

const primaryButtonStyles = css`
    color: var(--color-white);
    text-shadow: 0 1px 1px rgba(60, 101, 33, 0.22);

    background-color: var(--color-primary);
    border: 0;
    box-shadow: var(--box-shadow-mobile);

    ${roundedButtonStyling}

    &:hover,
    &:active {
        color: var(--color-white);

        background: var(--color-violet-50);
    }

    &:focus {
        color: var(--color-white);

        background: var(--color-violet-50);
        box-shadow: 0 2px 15px var(--color-violet-30);
    }
`;

const secondaryButtonStyles = css`
    color: var(--color-primary);

    background-color: var(--color-white);
    border: 0;

    ${roundedButtonStyling}

    &:hover {
        color: var(--color-violet-50);
    }

    &:active {
        color: var(--color-white);

        background-color: var(--color-violet-90);
    }

    &:focus {
        color: var(--color-violet-40);

        box-shadow: 0 2px 15px var(--color-violet-80);
    }
`;

const outlineButtonStyles = css`
    color: var(--color-violet-40);

    background-color: transparent;
    border: 1px solid var(--color-violet-50);

    ${roundedButtonStyling}

    svg {
        fill: var(--color-violet-40);
    }

    &:hover,
    &:active {
        color: var(--color-white);

        background: var(--color-violet-50);

        svg {
            fill: var(--color-white);
        }
    }

    &:focus {
        color: var(--color-violet-40);

        background-color: transparent;
        border: 1px solid var(--color-violet-50);
        box-shadow: 0 2px 15px var(--color-violet-40);
    }
`;

const ghostButtonStyled = css`
    margin: 8px 0;
    padding: 0;

    color: var(--color-secondary);
    font-weight: var(--font-weight-bold);
    font-size: var(--font-size-body);
    line-height: var(--line-height-body);

    background: transparent;
    border: 1px solid transparent;
    border-radius: 0;

    transition: all var(--animation-duration-default) ease-in-out;

    @media (any-hover: hover) {
        :active,
        :focus,
        :hover {
            border-bottom-color: var(--color-secondary);
        }
    }
`;

const inlineButtonStyled = css`
    margin: 0;
    padding: 10px 18px;

    color: var(--color-primary);
    font-weight: inherit;
    font-size: inherit;
    line-height: inherit;

    background: transparent;
    border: 0;

    transition: color var(--animation-duration-default) ease-in-out;

    &:hover,
    &:focus {
        color: var(--color-primary);
        text-decoration: underline;
    }
`;

const simpleLinkButtonStyled = css`
    margin: 0;
    padding: 0;

    color: var(--color-secondary);
    font-weight: var(--font-weight-bold);
    font-size: var(--font-size-body);
    line-height: var(--line-height-body);

    background-color: transparent;
    border: 1px solid transparent;
    border-radius: 0;

    transition: all var(--animation-duration-default) ease-in-out;

    @media (any-hover: hover) {
        :active,
        :focus,
        :hover {
            border-bottom-color: var(--color-secondary);
        }
    }
`;

const simpleUnderlinedButtonStyled = css`
    text-decoration: underline;

    ${inlineButtonStyled}
`;

const ButtonStyles = css`
    position: relative;

    display: inline-flex;
    gap: 0 5px;
    align-items: center;
    justify-content: center;

    outline: 0;
    cursor: pointer;

    user-select: none;

    &:disabled {
        cursor: not-allowed;
        opacity: 0.2;
    }

    ${({ variant }) =>
        (variant === "primary" && primaryButtonStyles) ||
        (variant === "secondary" && secondaryButtonStyles) ||
        (variant === "outline" && outlineButtonStyles) ||
        (variant === "ghost" && ghostButtonStyled) ||
        (variant === "inline" && inlineButtonStyled) ||
        (variant === "link" && simpleLinkButtonStyled) ||
        (variant === "underline" && simpleUnderlinedButtonStyled)}
`;

export const ButtonStyled = styled.button`
    ${ButtonStyles}
`;
