import PropTypes from "prop-types";

function IconCross({ fill, size, ...rest }) {
    return (
        <svg
            viewBox="0 0 64 64"
            width={size}
            height={size}
            fill={fill}
            clipRule="evenodd"
            fillRule="evenodd"
            {...rest}
        >
            <path d="m45.4 15-13.1 13.1-13.1-13.1-4.2 4.2 13.1 13.1-13.1 13.1 4.2 4.2 13.1-13 13.1 13 4.2-4.2-13-13.1 13-13.1z" />
        </svg>
    );
}

IconCross.propTypes = {
    fill: PropTypes.string,
    size: PropTypes.number,
    style: PropTypes.shape({ verticalAlign: PropTypes.string })
};

IconCross.defaultProps = {
    fill: "var(--color-black)",
    size: 48,
    style: { verticalAlign: "middle" }
};

export default IconCross;
