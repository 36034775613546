import styled, { css } from "styled-components";

import media from "constants/media";

export const Line = styled.span`
    position: absolute;

    display: block;
    width: 15px;
    height: 3px;

    background-color: var(--color-black);
    transform: rotate(0deg);

    transition: all var(--animation-duration-default) ease;
`;

export const Icon = styled.div`
    position: relative;

    display: block;
    width: 20px;
    height: 14px;

    ${Line} {
        ${({ open }) =>
            !open &&
            css`
                :nth-child(1) {
                    top: 0;
                }

                :nth-child(2) {
                    top: 6px;
                    width: 20px;
                }

                :nth-child(3) {
                    top: 12px;
                }
            `}

        ${({ open }) =>
            open &&
            css`
                :nth-child(1) {
                    top: 6px;
                    width: 20px;
                    height: 2px;

                    transform: rotate(45deg);
                }
                :nth-child(2) {
                    top: 6px;
                    width: 20px;
                    height: 2px;

                    transform: rotate(-45deg);
                }
                :nth-child(3) {
                    top: 6px;

                    opacity: 0;
                }
            `}
    }
`;

export const Text = styled.span`
    display: block;

    color: var(--color-gray-70);
    font-weight: var(--font-weight-bold);
    font-size: 8px;
    line-height: 10px;

    transition: all var(--animation-duration-default) ease;
`;

export const MenuButton = styled.button`
    position: relative;
    z-index: 33;

    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 2px 0;
    align-items: flex-start;
    justify-content: center;
    width: 50px;
    padding: 0 0 0 14px;

    background: transparent;
    border: 0;
    cursor: pointer;

    @media (any-hover: hover) {
        :hover {
            ${Line} {
                background-color: var(--color-primary);
            }

            ${Text} {
                color: var(--color-primary);
            }
        }
    }

    ${media.mdUp} {
        padding: 0 15px;
    }

    ${({ open }) =>
        !open &&
        css`
            border-right: 1px solid var(--color-gray-80);

            ${media.mdUp} {
                border-right: 0;
            }
        `}
`;
