import slugify from "slugify";

export function scrollToRef(ref) {
    ref.current?.scrollIntoView({
        behavior: "smooth",
        block: "center"
    });
}

export const renderFinalPrice = (value, options) => {
    let string;
    const canBeZero = options && options.canBeZero ? options.canBeZero : false;
    const locale = options && options.locale ? options.locale : "nl-NL";
    const useDash = options && options.useDash ? options.useDash : false;

    if (Number(value) === 0 && !canBeZero) {
        return "Gratis";
    }

    const formatter = new Intl.NumberFormat(locale, {
        style: "currency",
        currency: "EUR"
    });

    string = formatter.format(Number(value));

    if (useDash) {
        string = string.replace(/,00/, ",-");
    }

    return string;
};

export const isExternalLink = url => {
    return !url.startsWith("/");
};

export function generateQueryString(object, encode = false) {
    if (encode) {
        return Object.entries(object)
            .map(key => key.map(encodeURIComponent).join("="))
            .join("&");
    }

    return Object.keys(object)
        .map(key => `${key}=${object[key]}`)
        .join("&");
}

export function hexToRgba(hex, opacity = 1) {
    const rgb = hex
        .replace(
            /^#?([a-f\d])([a-f\d])([a-f\d])$/i,
            (m, r, g, b) => `#${r}${r}${g}${g}${b}${b}`
        )
        .substring(1)
        .match(/.{2}/g)
        .map(x => parseInt(x, 16))
        .toString();

    return `rgba(${rgb}, ${opacity})`;
}

export function generateSlug(slug) {
    return slugify(slug, {
        lower: true
    });
}

export function containsHTML(content) {
    if (typeof content === "string") {
        return /<\/?[a-z][\s\S]*>/i.test(content);
    }

    return false;
}

export function isNotEmpty(value) {
    return value !== undefined && value !== null && value.length > 0;
}

export function propertyExists(property) {
    return property !== undefined && property !== null && property !== "";
}
