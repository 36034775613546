import Link from "next/link";

import { useMediaQueryContext } from "helpers/hooks/useMediaQueryContext";

import navigationItems from "constants/navigation";

import { Container, List, Anchor } from "./Navigation.styled";

function Navigation() {
    const { containerWidth } = useMediaQueryContext();

    return (
        <Container
            containerWidth={containerWidth}
            role="navigation"
            aria-label="Main"
        >
            <List>
                {navigationItems.map((item, index) => (
                    <li key={`nav-${index}-${item.label}`}>
                        <Link href={item.url} passHref prefetch={false}>
                            <Anchor last={index === navigationItems.length - 1}>
                                {item.label}
                            </Anchor>
                        </Link>
                    </li>
                ))}
            </List>
        </Container>
    );
}

export default Navigation;
